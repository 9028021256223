import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AppBar as MuiAppBar,
  Toolbar,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import { useIntl } from 'react-intl';

import { ViewSwitchIconRouter } from './ViewSwitchIconRouter';
import { Avatar } from '../Icons';
import { Heading4, Note } from '../../typography';
import SecondaryBar from './SecondaryBar';
import BarButton from './BarButton';
import ToolbarSpacer from './ToolbarSpacer';
import { formatDate } from '../../format';
import { useRetryPeriod } from '../../data/hooks/useRetryPeriod';

const useStyles = makeStyles(
  theme => ({
    container: {
      top: 0,
      left: 'auto',
      right: 0,
      position: 'fixed',
      width: '100%',
      zIndex: theme.zIndex.appBar,
    },
    appBar: {
      boxShadow: 'none',
    },
    toolbar: {
      position: 'relative',
      justifyContent: 'center',
      paddingTop: 'env(safe-area-inset-top)',
    },
    title: {
      display: 'flex',
      flex: 1,
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectLocationButton: {
      borderRadius: 0,
      flex: 1,
      alignSelf: 'stretch',
      paddingLeft: `calc(${theme.spacing(1.5)}px + env(safe-area-inset-left))`,
      paddingRight: `calc(${theme.spacing(
        1.5
      )}px + env(safe-area-inset-right))`,
    },
    iconTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      paddingLeft: `calc(${theme.spacing(1.5)}px + env(safe-area-inset-left))`,
      paddingRight: `calc(${theme.spacing(
        1.5
      )}px + env(safe-area-inset-right))`,
    },
    avatar: {
      position: 'absolute',
      alignSelf: 'center',
      right: `calc(${theme.spacing(1.5)}px + env(safe-area-inset-right))`,
    },
  }),
  { name: 'LriAppBar' }
);

const MainTitle = ({ title, subtitle }) => (
  <div>
    <Heading4 align="center" color="inherit">
      {title}
    </Heading4>
    <Note
      align="center"
      color="inherit"
      gray
      style={{ marginTop: 2, textTransform: 'none' }}
    >
      {subtitle}
    </Note>
  </div>
);

const AppBar = ({
  onPeriodChangeClick,
  mainTitle,
  subtitle,
  secondaryTitle,
  toggleLocationSelect,
  onAvatarClick,
  showPeriodsBar = true,
  period,
  onViewSwitchClick,
  viewTypeButton,
}) => {
  const styles = useStyles();
  const { retryPeriod } = useRetryPeriod();
  const { formatMessage: f } = useIntl();

  const handleViewSwitchClick = () => {
    onViewSwitchClick();
  };

  const refreshDate = retryPeriod
    ? period?.currentDate
    : parseISO(period?.currentDate);
  const dateLabel = () => {
    if (secondaryTitle.id === 'st') {
      return `(${moment(period.startDate).format('DD MMM YYYY')} - ${moment(
        period.endDate
      ).format('DD MMM YYYY')})`;
    } else {
      return `(${f({ id: 'app.includes_data_through' })} ${
        period?.currentDate ? formatDate(refreshDate) : ''
      })`;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <MuiAppBar className={styles.appBar} position="static">
          <Toolbar className={styles.toolbar} disableGutters={true}>
            <div className={styles.title}>
              {toggleLocationSelect ? (
                <BarButton
                  className={styles.selectLocationButton}
                  color="inherit"
                  onClick={() => toggleLocationSelect()}
                >
                  <ViewSwitchIconRouter
                    onClick={handleViewSwitchClick}
                    viewTypeButton={viewTypeButton}
                  />
                  <MainTitle title={mainTitle} subtitle={subtitle} />
                </BarButton>
              ) : (
                <div className={styles.iconTitleContainer}>
                  <ViewSwitchIconRouter
                    onClick={handleViewSwitchClick}
                    viewTypeButton={viewTypeButton}
                  />
                  <MainTitle title={mainTitle} subtitle={subtitle} />
                </div>
              )}
            </div>
            <IconButton
              onClick={onAvatarClick}
              className={styles.avatar}
              color="inherit"
              edge="end"
            >
              <Avatar />
            </IconButton>
          </Toolbar>
        </MuiAppBar>
        {showPeriodsBar &&
          (period?.currentDate || secondaryTitle.id === 'st') && (
            <SecondaryBar
              text={f({ id: `${secondaryTitle.label}` })}
              subText={dateLabel()}
              onClick={onPeriodChangeClick}
            />
          )}
      </div>
      <div style={{ height: 'env(safe-area-inset-top)' }} />
      <ToolbarSpacer />
      {showPeriodsBar && <ToolbarSpacer variant="dense" />}
    </>
  );
};

AppBar.propTypes = {
  onPeriodChangeClick: PropTypes.func,
  mainTitle: PropTypes.string,
  secondaryTitle: PropTypes.object,
  toggleLocationSelect: PropTypes.func,
  onAvatarClick: PropTypes.func,
};

export default AppBar;
