import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Header from './Header';
import BarChart from './Charts/Bar';
import LoadingBar from './LoadingBar';
import SectionSpacer from './SectionSpacer';
import { CardGrid, SalesCard } from './Cards';
import { ErrorPageOrChildren } from './Errors';
import { PageView } from '../gtag';
import ErrorBoundary from '../ErrorBoundary';
import useFullProductsCompareData from '../data/hooks/useFullProductsCompareData';

const GenderCards = ({ data, useLLY }) => {
  const { formatMessage: f } = useIntl();

  return (
    <CardGrid>
      {Object.entries(data).map(([k, v]) => (
        <SalesCard
          key={k}
          topLabel={v.formattedSum}
          middleLabel={v.formattedPercentage}
          bottomLabel={f({ id: v.genderLabel })}
          color={k.includes('menA') ? 'primary' : 'secondary'}
          delta={v.dpy}
          useLLY={useLLY}
        />
      ))}
    </CardGrid>
  );
};

GenderCards.propTypes = {
  data: PropTypes.shape({
    formattedSum: PropTypes.string,
    formattedPercentage: PropTypes.string,
    genderLabel: PropTypes.string,
  }),
};

const styles = theme => ({
  barChart: {
    marginTop: theme.spacing(2),
  },
  heading: {
    paddingTop: '20px',
  },
});

const ProductsStock = ({ classes, data, useLLY }) => {
  const [chartData, setChartData] = useState(data);
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    const tempChartData = JSON.parse(JSON.stringify(data));
    tempChartData?.chart &&
      Object.keys(tempChartData.chart).forEach(key => {
        tempChartData.chart[key].labels = tempChartData.chart[
          key
        ].labels.map(transformedLabel => f({ id: `${transformedLabel}` }));
      });
    setChartData(tempChartData);
  }, [data, f]);

  return (
    <>
      <GenderCards data={data?.totals['adults']} useLLY={useLLY} />
      {chartData?.chart?.['adults'] && (
        <BarChart
          className={classes.barChart}
          data={chartData.chart['adults']}
        />
      )}
      <SectionSpacer />
    </>
  );
};

const ProductCompare = ({
  classes,
  period,
  locationA: locationAndParamsA,
  locationB: locationAndParamsB,
  userSettings,
}) => {
  const locationId = locationAndParamsA.location.id;
  const { data, loading, error, refetch } = useFullProductsCompareData(
    locationId,
    locationAndParamsA.params,
    locationAndParamsB.params,
    period?.id,
    userSettings.useUsdAmount,
    userSettings.includeTaxes,
    userSettings.useLLY
  );
  const { formatMessage: f } = useIntl();

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <PageView
      params={{
        period_id: period?.id,
        location_id: locationAndParamsA.location.name,
        channel: locationAndParamsA.params.channel,
      }}
    >
      <div className={classes.heading}>
        <Header title={f({ id: 'app.gender_mix_sales' })} />
      </div>
      <ErrorPageOrChildren
        message={f({ id: 'app.could_not_load_sales_data' })}
        errors={[error]}
        onClickRetry={refetch}
      >
        <>
          {data?.menStock && (
            <ErrorBoundary>
              <ProductsStock
                classes={classes}
                data={data?.menStock}
                useLLY={userSettings.useLLY}
              />
            </ErrorBoundary>
          )}
          {data?.womenStock && (
            <ErrorBoundary>
              <ProductsStock
                classes={classes}
                data={data?.womenStock}
                useLLY={userSettings.useLLY}
              />
            </ErrorBoundary>
          )}
        </>
      </ErrorPageOrChildren>
    </PageView>
  );
};

export default withStyles(styles)(ProductCompare);
