import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Select, SelectDrawer } from '../../components/Select';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';
import usePeriodData from '../hooks/usePeriodData';
import { subMilliseconds, subDays, subMonths, subYears } from 'date-fns';
import LoadingBar from '../../components/LoadingBar';
import { useRetryPeriod } from '../hooks/useRetryPeriod';
import DateRangePicker from './DateRangePicker';

const fixedPeriodOptions = [
  { id: 'pd', label: 'app.pd' },
  { id: 'wtd', label: 'app.wtd' },
  { id: 'mtd', label: 'app.mtd' },
  { id: 'qtd', label: 'app.qtd' },
  { id: 'ytd', label: 'app.ytd' },
  { id: 'st', label: 'app.st' },
];

const periodKeys = {
  pd: 'day',
  wtd: 'week',
  mtd: 'month',
  qtd: 'quarter',
  ytd: 'year',
  st: 'specificTimeFrame',
};

const scrollToBottom = () => {
  const element = document.getElementById('datePickerContainer');
  if (element) {
    element.scrollIntoView();
  }
};

const getInitialPeriod = (items, pm) => {
  if (!items) return null;
  return items.find(x => x.id === pm.get('period')) || items[0];
};

const convertTimeStamp = (date = new Date()) => {
  const utc =
    new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000;
  return utc;
};

const findPeriod = (periodList, date) => {
  const test =
    periodList.find(period => {
      return (
        convertTimeStamp(period.startDate) <= convertTimeStamp(date) &&
        convertTimeStamp(period.endDate) > convertTimeStamp(date)
      );
    }) || {};
  return test;
};

const PeriodsShell = ({ children, onClose = noop }) => {
  const pm = usePreferencesManager();
  const { formatMessage: f } = useIntl();
  const { data: periods, loading, error } = usePeriodData();
  const [drawer, setDrawer] = useState(false);
  const [selected, setSelected] = useState(() =>
    getInitialPeriod(fixedPeriodOptions, pm)
  );
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (selected.id === 'st') {
      scrollToBottom();
    }
  }, [selected.id]);

  const toggleSelect = () => {
    setDrawer(true);
  };

  const { retryPeriod, setRetryPeriod } = useRetryPeriod();
  const handleSelect = (item, startDate = null, endDate = null) => {
    setSelected(item);
    if (item.id !== 'st') {
      setRetryPeriod(false);
      pm.set('period', item.id);
      onClose();
      setDrawer(false);
    } else {
      setDateRange([startDate, endDate]);
    }
  };

  const getSelectedPeriod = () => {
    const key = periodKeys[selected.id];
    const subAmount = key => {
      switch (key) {
        case 'day':
          return subDays(new Date(), 2);
        case 'week':
          return subDays(new Date(), 7);
        case 'month':
          return subMonths(new Date(), 1);
        case 'quarter':
          return subMonths(new Date(), 3);
        case 'year':
          return subYears(new Date(), 1);
        default:
          return subDays(new Date(), 1);
      }
    };

    const date = retryPeriod ? subAmount(key) : subDays(new Date(), 1);
    const period =
      selected.id === 'st'
        ? { startDate: dateRange[0], endDate: dateRange[1] }
        : findPeriod(periods[key], date);

    const eDate = subMilliseconds(period.endDate, 1000);
    period.currentDate = retryPeriod ? eDate : period.refreshDate;
    return period;
  };

  const getTodaysPeriod = () => {
    const date = new Date();
    const period = findPeriod(periods.day, date);
    return period;
  };

  const getTranslatedList = () =>
    fixedPeriodOptions.map(option => {
      return {
        ...option,
        label: f({ id: option.label }),
      };
    });

  let err = error;

  return (
    <>
      {err && children(null, null, () => null, err)}
      {loading && <LoadingBar />}
      {!err && periods && (
        <>
          {children(selected, toggleSelect, getSelectedPeriod, getTodaysPeriod)}
          <SelectDrawer
            open={drawer}
            title={f({ id: 'app.select_time_period' })}
            onClose={() => {
              onClose();
              setDrawer(false);
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <Select
                items={getTranslatedList()}
                selected={selected}
                onSelect={handleSelect}
              />
              {selected.id === 'st' && (
                <div id="datePickerContainer">
                  <DateRangePicker
                    onDateRangeSelect={(startDate, endDate) =>
                      handleSelect(selected, startDate, endDate)
                    }
                    closeDrawer={() => setDrawer(false)}
                  />
                </div>
              )}
            </div>
          </SelectDrawer>
        </>
      )}
    </>
  );
};

PeriodsShell.propTypes = {
  onClose: PropTypes.func,
};

export default PeriodsShell;
